import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useVclApi,
  HTTP_METHODS,
  VclApiGetType,
  IWebcast,
  useLocalStorage,
  VclApiProps,
} from 'vcl-common';
import AddButton from './addButton';
import MessageBar, { Types } from './messageBar';
import SearchBar from './searchBar';
import { loginRequest } from '../utils/msalConfig';

interface VCLDataGridProps {
  title: string;
  entityRoute?: string;
  apiUrl: string;
  getPersonalizedData: boolean;
  gridColumns: any[];
  canUserAdd: boolean;
  filters?: any;
  defaultFilterName?: string;
  classes?: any;
  viewRoute?: string;
  showSearchBar?: boolean;
  showSelectOption?: boolean;
  canUserViewGrid: boolean;
  disableRowClick?: boolean;
  onAddClick?: any;
  initialSelectedIds?: any[];
  getSelectedIds?: (ids: any[]) => void;
  className?: string;
}

const VCLDataGrid = (props: VCLDataGridProps) => {
  const {
    title,
    entityRoute,
    apiUrl,
    getPersonalizedData,
    gridColumns,
    canUserAdd,
    filters,
    defaultFilterName,
    classes,
    viewRoute,
    showSearchBar = true,
    canUserViewGrid,
    disableRowClick = false,
    onAddClick = null,
    showSelectOption = false,
    initialSelectedIds = null,
    getSelectedIds = null,
    className = 'dataGridContainer',
  } = props;
  const navigate = useNavigate();
  const [rows, setRows] = useState<IWebcast[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [historySelectedId, setHistorySelected] = useState(null);
  const [filter, setFilter] = useState(defaultFilterName);
  const [dialog, setDialog] = useState({ type: '', message: '' });
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const { getGlobalSettings } = useLocalStorage();
  const globalSettings = getGlobalSettings();
  const pageSize = globalSettings?.siteSettings?.defaultAPIPageSize;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });

  // Memoize calculated values to prevent unnecessary re-rendering
  const selectedFilter = useMemo(() => {
    if (filter && filter.length > 0 && filters && filters.length > 0) {
      const filterMatch = filters.filter((f: any) => f.name === filter);
      if (filterMatch && filterMatch.length > 0) {
        return {
          ...filterMatch[0].filter,
          searchText,
        };
      }
    } else if (showSearchBar && searchText && searchText.length > 0) {
      return { searchText };
    }
    return null;
  }, [filter, filters, searchText, showSearchBar]);
  const currentPage = useMemo(
    () => paginationModel.page + 1,
    [paginationModel.page],
  );
  const modifiedApiUrl = useMemo(
    () => (getPersonalizedData ? apiUrl + '/my' : apiUrl),
    [apiUrl, getPersonalizedData],
  );
  const apiProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: modifiedApiUrl,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Batch,
      page: currentPage,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
      fetchImmediately: true,
      filter: selectedFilter,
    };
  }, [currentPage, modifiedApiUrl, selectedFilter]);

  const { apiResponseBatch, apiRequestInProgress, apiError } =
    useVclApi<IWebcast>(apiProps);

  if (apiResponseBatch && !apiRequestInProgress && !apiError?.isError) {
    let items = apiResponseBatch.items;
    let count = apiResponseBatch.properties.totalItemCount;

    if (
      apiResponseBatch.items.length > 0 &&
      initialSelectedIds &&
      initialSelectedIds.length > 0
    ) {
      items = apiResponseBatch.items.filter(
        (webcast) => !initialSelectedIds.includes(webcast.id),
      );
      count =
        apiResponseBatch.properties.totalItemCount - initialSelectedIds.length;
    }

    if (JSON.stringify(rows) !== JSON.stringify(items)) {
      setRows(items);
    }
    if (rowCount !== count) {
      setRowCount(count);
    }
  } else if (apiError?.isError) {
    setDialog((current) => {
      const nState = { ...current };
      nState.type = Types.error;
      nState.message = `Error occured when loading the requested data: ${apiError.errorMessage}`;
      return nState;
    });
  }

  if (!canUserViewGrid && dialog.type === '') {
    setDialog((current) => {
      const nState = { ...current };
      nState.type = Types.error;
      nState.message = 'You do not have permissions to view this data.';
      return nState;
    });
  }

  const getFilterButtons = () => {
    return filters.map((f: any) => {
      return (
        <ToggleButton value={f.name} key={f.name}>
          {f.title}
        </ToggleButton>
      );
    });
  };

  const handleRowClick = (params: any) => {
    if (disableRowClick) return;
    if (viewRoute) {
      navigate(`${viewRoute}/${params.id}`);
    } else {
      navigate(`${entityRoute}/${params.id}`);
    }
  };

  const handleAddClick = (event: any) => {
    if (onAddClick) {
      onAddClick();
      return;
    }
    navigate(`${entityRoute}/new`);
  };

  const handleViewChange = (event: any, newView: any) => {
    // prevent to click when loading is happening
    if (!apiRequestInProgress) setFilter(newView);
  };

  function onConfirmButtonClick() {
    setDialog((current) => {
      const nState = { ...current };
      nState.message = '';
      return nState;
    });
  }

  const handleSelectionChange = (newSelection: any) => {
    if (getSelectedIds) {
      setSelectedRows(newSelection);
      getSelectedIds(newSelection);
    }
  };

  return (
    <>
      {canUserViewGrid && (
        <div className={`view ${classes && classes.vclDataGrid} mouseIconP`}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={2} lg={4}>
              <h2>{title}</h2>
            </Grid>
            {filters && filters.length > 0 && (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <ToggleButtonGroup
                  size="small"
                  color="secondary"
                  value={filter}
                  exclusive
                  onChange={handleViewChange}
                >
                  {getFilterButtons()}
                </ToggleButtonGroup>
              </Grid>
            )}
            {showSearchBar && (
              <Grid item xs={12} sm={6} md={6} lg={4} textAlign="right">
                <SearchBar
                  onSearch={(searchText: string) => {
                    setSearchText(searchText);
                  }}
                ></SearchBar>
              </Grid>
            )}
          </Grid>
          <div className={className}>
            <div className="vcldgflexGrow">
              <DataGrid
                disableColumnFilter
                disableRowSelectionOnClick={disableRowClick}
                onRowClick={handleRowClick}
                columns={gridColumns}
                rows={rows}
                loading={apiRequestInProgress}
                rowCount={rowCount}
                pageSizeOptions={[pageSize]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                checkboxSelection={showSelectOption}
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectedRows}
                getRowClassName={(params: any) =>
                  `row-style--${params.row.isDeleted ? 'deleted' : 'normal'}`
                }
              />
              {canUserAdd && <AddButton handleClick={handleAddClick} />}
              <Dialog
                fullScreen={false}
                maxWidth={'lg'}
                open={historySelectedId !== null}
                onClose={() => setHistorySelected(null)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">Audit Log</DialogTitle>
                <DialogContent dividers={false}>
                  {/* <AuditLogListing
                    api={api.current}
                    type="subnet"
                    id={historySelectedId}
                  /> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setHistorySelected(null)}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              {canUserAdd && <AddButton handleClick={handleAddClick} />}
              <Dialog
                fullScreen={false}
                maxWidth={'lg'}
                open={historySelectedId !== null}
                onClose={() => setHistorySelected(null)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">Audit Log</DialogTitle>
                <DialogContent dividers={false}>
                  {/* <AuditLogListing
                    api={api.current}
                    type="subnet"
                    id={historySelectedId}
                  /> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setHistorySelected(null)}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      )}
      {dialog.message && (
        <MessageBar
          confirmationType={dialog.type}
          message={dialog.message}
          delayTimeMS={1500}
          reset={dialog.type === Types.error ? 'true' : ''}
          open={true}
          buttonText="Close"
          onButtonClick={onConfirmButtonClick}
          performAction={() =>
            setDialog((current) => {
              const nState = { ...current };
              nState.message = '';
              return nState;
            })
          }
        />
      )}
    </>
  );
};

export default VCLDataGrid;
