import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabbedEditor } from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import { Constants } from 'vcl-common';
import { validateMetadata, validateRequired } from '../utils/validators';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';

const initMetadata = (current?: any) =>
  initEntityMetadata(
    [
      new EntityProp('id', 0),
      new EntityProp('userId', '', [validateRequired]),
      new EntityProp('user', null),
    ],
    current,
  );

export default function GlobalAdmin() {
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [canEdit, setCanEdit] = useState(false);

  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} key="panel_0" index={0} mode="tabs">
          <TextField
            fullWidth
            required
            id="userId"
            name="userId"
            label="User ID"
            value={metadata.userId.value}
            error={!metadata.userId.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          {itemId !== 'new' && (
            <TextField
              fullWidth
              required
              id="fullName"
              name="fullName"
              label="Full Name"
              value={metadata.user.value?.fullName}
              disabled={true}
            />
          )}
          {itemId !== 'new' && (
            <TextField
              fullWidth
              required
              id="userName"
              name="userName"
              label="Username"
              value={metadata.user.value?.userName}
              disabled={true}
            />
          )}
        </TabPanel>
      ),
    },
  ];

  return (
    <TabbedEditor<number>
      entityName="Global Admin"
      apiUrl={Constants.routes.api.globalAdmins}
      entityRoute={Constants.routes.adminCenter.globalAdmins}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      setMetadata={setMetadata}
      canEdit={canEdit}
      canDelete={canEdit}
      setCanEdit={setCanEdit}
      usePermissions={false}
      initMetadataFn={initMetadata}
      entityId={itemId}
    />
  );
}
