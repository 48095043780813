import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import {
  Constants,
  HTTP_METHODS,
  IUser,
  VclApiGetType,
  VclApiProps,
  useVclApi,
} from 'vcl-common';
import { PermissionContext } from './permissionList';
import { loginRequest } from '../utils/msalConfig';
import { AxiosResponse } from 'axios';

type AddUsersProps = {
  show: boolean;
  onAdd: any;
  validPermissionLevels: any[];
  onClose: any;
};

export default function AddUsers(props: AddUsersProps) {
  const permissionContext = useContext(PermissionContext);
  const { show, onAdd, validPermissionLevels, onClose } = props;
  const [permissionLevel, setLevel] = useState(validPermissionLevels[0]);
  const [isExternalUser, setIsExternalUser] = useState<boolean>(false);
  const [externalUser, setExternalUser] = useState<string>('');
  const [internalUser, setInternalUser] = useState<string>('');

  const apiGetProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.resolveusers,
      method: HTTP_METHODS.POST,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, []);
  const { callApi } = useVclApi<IUser[]>(apiGetProps);

  const updateLevel = (levelId: any) => {
    const newLevel = validPermissionLevels.filter((l: any) => {
      return l.id === levelId;
    });

    if (newLevel.length === 1) setLevel(newLevel[0]);
  };

  const addUsers = async () => {
    if (isExternalUser && externalUser.trim().length > 0) {
      const formData = new FormData();
      formData.append(
        'identityProvider',
        String(Constants.identityProvider.email),
      );
      formData.append('emails', JSON.stringify(externalUser));

      const response = (await callApi(
        undefined,
        undefined,
        undefined,
        formData,
      )) as AxiosResponse<IUser[]>;

      const users = response.data.map((user: IUser) => {
        return {
          displayName: user.fullName,
          userPrincipalName: user.userName,
          federatedUserId: user.federatedUserId,
          identityProvider: Constants.identityProvider.email,
        };
      });
      if (users.length > 0)
        onAdd(users, permissionLevel.id, Constants.identityProvider.email);
    } else if (internalUser.trim().length > 0) {
      const formData = new FormData();
      formData.append(
        'identityProvider',
        String(Constants.identityProvider.azureAD),
      );
      formData.append('emails', JSON.stringify(internalUser));

      const response = (await callApi(
        undefined,
        undefined,
        undefined,
        formData,
      )) as AxiosResponse<IUser[]>;

      const users = response.data.map((user: IUser) => {
        return {
          displayName: user.fullName,
          userPrincipalName: user.userName,
          federatedUserId: user.federatedUserId,
          id: user.federatedUserId,
          identityProvider: Constants.identityProvider.azureAD,
        };
      });
      if (users.length > 0)
        onAdd(users, permissionLevel.id, Constants.identityProvider.azureAD);
    }
    closeMe();
  };

  const closeMe = () => {
    setExternalUser('');
    setIsExternalUser(false);
    setInternalUser('');
    setLevel(validPermissionLevels[0]);
    onClose();
  };

  const showAddExternalUsers = () => {
    if (
      permissionContext !== null &&
      permissionContext.entity === 'Webcast' &&
      permissionContext.metadata !== null
    ) {
      const webcast = permissionContext.metadata;
      if (webcast !== null) {
        return !webcast.requireOrgSignIn.value && webcast.requireInvite.value;
      }
    }
    return false;
  };

  const getValidPermissionLevels = () => {
    if (
      permissionContext !== null &&
      permissionContext.entity === 'Webcast' &&
      permissionContext.metadata !== null
    ) {
      if (isExternalUser && permissionContext.metadata.requireInvite?.value) {
        return validPermissionLevels.filter((_: any) => _.title === 'Viewer');
      }
    }
    return validPermissionLevels;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={show}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <span>Add users</span>
          </Grid>
          {showAddExternalUsers() && (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isExternalUser}
                    onChange={(event, value) => {
                      setIsExternalUser(value);
                      setInternalUser('');
                      setExternalUser('');
                    }}
                    name="addExternalUsers"
                    color="primary"
                    disabled={false}
                  />
                }
                label="Add External Users"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true} className="dialogContentHeight">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id={isExternalUser ? 'externalUserEmails' : 'internalUserEmails'}
              name={
                isExternalUser ? 'externalUserEmails' : 'internalUserEmails'
              }
              label={
                isExternalUser
                  ? 'Required email addresses and/or wildcard patterns for external sign in'
                  : 'Add email Addresses'
              }
              value={isExternalUser ? externalUser : internalUser}
              onChange={(event) => {
                if (isExternalUser) {
                  setExternalUser(event.target.value);
                } else {
                  setInternalUser(event.target.value);
                }
              }}
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            {permissionLevel && (
              <FormControl fullWidth>
                <InputLabel id="permLevel">Role</InputLabel>
                <Select
                  labelId="permLevel"
                  id="permLevel-select"
                  value={permissionLevel.id}
                  label="Role"
                  onChange={(event) => updateLevel(event.target.value)}
                >
                  {getValidPermissionLevels().map((level: any, index: any) => (
                    <MenuItem key={`level-${index}`} value={level.id}>
                      {level.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            isExternalUser
              ? externalUser.trim().length <= 0
              : internalUser.trim().length <= 0
          }
          onClick={() => addUsers()}
        >
          Add Selected
        </Button>
        <Button onClick={() => closeMe()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
