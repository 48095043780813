import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TabbedEditor } from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import { Constants, useLocalStorage } from 'vcl-common';
import { validateMetadata, validateRequired } from '../utils/validators';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';

const initMetadata = (current?: any) =>
  initEntityMetadata(
    [
      new EntityProp('id', crypto.randomUUID()),
      new EntityProp('fullName', '', [validateRequired]),
      new EntityProp('userName', '', [validateRequired]),
      new EntityProp('identityProvider', '', [validateRequired]),
      new EntityProp('federatedUserId', '', [validateRequired]),
    ],
    current,
  );

export default function User() {
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [canEdit, setCanEdit] = useState(false);

  const { getGlobalSettings } = useLocalStorage();
  const globalSettings = getGlobalSettings();

  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} key="panel_0" index={0} mode="tabs">
          <TextField
            fullWidth
            required
            id="fullName"
            name="fullName"
            label="Full Name"
            value={metadata.fullName.value}
            error={!metadata.fullName.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <TextField
            fullWidth
            required
            id="userName"
            name="userName"
            label="Username"
            value={metadata.userName.value}
            error={!metadata.userName.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <FormControl fullWidth>
            <InputLabel id="identityProvider">Identity Provider *</InputLabel>
            <Select
              required
              error={!metadata.identityProvider.isValid}
              labelId="identityProvider"
              id="identityProvider-select"
              value={metadata.identityProvider.value}
              label="Identity Provider"
              onChange={(event) =>
                setMetadata(
                  validateMetadata(metadata, event, 'identityProvider'),
                )
              }
            >
              {globalSettings?.siteSettings &&
                globalSettings?.siteSettings?.identityProviders &&
                globalSettings?.siteSettings?.identityProviders.map(
                  (provider: any) => (
                    <MenuItem key={provider.name} value={provider.id}>
                      {provider.name}
                    </MenuItem>
                  ),
                )}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            required
            id="federatedUserId"
            name="federatedUserId"
            label="Federated User ID"
            value={metadata.federatedUserId.value}
            error={!metadata.federatedUserId.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
        </TabPanel>
      ),
    },
  ];

  return (
    <TabbedEditor
      entityName="User"
      apiUrl={Constants.routes.api.users}
      entityRoute={Constants.routes.adminCenter.users}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      setMetadata={setMetadata}
      canEdit={canEdit}
      canDelete={canEdit}
      setCanEdit={setCanEdit}
      usePermissions={false}
      initMetadataFn={initMetadata}
      entityId={itemId}
    />
  );
}
