import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ImageViewCard from '../sharedComponents/imageViewCard';
import LazyAutocomplete from '../sharedComponents/lazyAutocomplete';
import { TabbedEditor } from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import {
  Constants,
  formatImageFilename,
  getDefaultHeroFromParent,
  getDefaultThumbnailFromParent,
  getHostDependentImageUrl,
  useLocalStorage,
} from 'vcl-common';
import {
  validateEmail,
  validateMetadata,
  validateRequired,
  validateUrl,
} from '../utils/validators';
import { getLocalDateFromString } from '../utils/dateTime';
import { webcastAvailablePermissions } from '../utils/permissions';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';

const useStyles = makeStyles()((theme) => ({
  dtError: {
    '& label': {
      color: '#d32f2f !important',
    },
    '& fieldset': {
      borderColor: '#d32f2f !important',
    },
    '& p': {
      color: '#d32f2f !important',
    },
  },
  dependentSelects: {
    marginLeft: theme.spacing(-1),
    marginTop: 0,
  },
  formControl: {
    marginLeft: 0, // Adjust the left margin as needed
  },
}));

export default function Webcast() {
  const { getOrgUserProfile, getGlobalSettings } = useLocalStorage();

  const initMetadata = useCallback(
    (current?: any) => {
      const userProfile = getOrgUserProfile();
      let _metaData = initEntityMetadata(
        [
          new EntityProp('id', 0),
          new EntityProp('isDeleted', false),
          new EntityProp('allowEmbed', true),
          new EntityProp('title', '', [validateRequired]),
          new EntityProp('contactEmail', userProfile?.mail, [
            validateRequired,
            validateEmail,
          ]),
          new EntityProp('title2', ''),
          new EntityProp('description', ''),
          new EntityProp('descriptionFieldContainsHTML', false),
          new EntityProp('location', null),
          new EntityProp('locationId', null, [validateRequired]),
          new EntityProp('channel', null),
          new EntityProp('channelId', null),
          new EntityProp(
            'startTime',
            null,
            [validateRequired],
            [getLocalDateFromString],
          ),
          new EntityProp(
            'endTime',
            null,
            [validateRequired],
            [getLocalDateFromString],
          ),
          new EntityProp('customCDSwitchOverTime', null, null, [
            getLocalDateFromString,
          ]),
          new EntityProp('useCustomCDSwitchOver', false),
          new EntityProp('lobbyDescription', ''),
          new EntityProp('lobbyDescriptionFieldContainsHTML', false),
          new EntityProp('announcements', null),
          new EntityProp('chatMessages', null),
          new EntityProp('enableCTAButton', false),
          new EntityProp('ctaButtonHeadline', ''),
          new EntityProp(
            'ctaButtonTitle',
            'VIEW',
            [validateRequired],
            null,
            true,
          ),
          new EntityProp(
            'ctaButtonUrl',
            '',
            [validateRequired, validateUrl],
            null,
            true,
          ),
          new EntityProp('ctaButtonDescription', ''),
          new EntityProp('showCTAButtonInVod', false),
          new EntityProp('hideStatistics', false),
          new EntityProp('ViewCount', 0),
          new EntityProp('statsMinimal', null),
          new EntityProp('statsFull', null),
          new EntityProp('postEventBehavior', 1),
          new EntityProp(
            'redirectUrl',
            '',
            [validateRequired, validateUrl],
            null,
            true,
          ),
          new EntityProp('voDChaptersFilePath', null),
          new EntityProp('voDFirstPublished', null),
          new EntityProp('voDPlayDuration', null),
          new EntityProp('voDPlayStart', null),
          new EntityProp('voDStatus', 0),
          new EntityProp('voDSubsFilePath', null),
          new EntityProp('requirePin', false),
          new EntityProp('allowExternalEmailSignin', false),
          new EntityProp('requireOrgSignIn', true),
          new EntityProp('requireInvite', false),
          new EntityProp('signInRequiredForExternalUsers', false),
          new EntityProp('shareContent', null),
          new EntityProp('accessKey', ''),
          new EntityProp('approvalState', 0), // These fields are left for possible future approval feature
          new EntityProp('approvalMotivation', ''),
          new EntityProp('approvalResponse', ''),
          new EntityProp('customHeroUrl', null),
          new EntityProp('customThumbnailUrl', null),
          new EntityProp('defaultHeroUrl', null),
          new EntityProp('defaultThumbnailUrl', null),
          new EntityProp('region', null),
          new EntityProp('regionId', null, [validateRequired]),
          new EntityProp('permissions', []),
          new EntityProp(
            'availablePermissionLevels',
            webcastAvailablePermissions,
          ),
          new EntityProp('excludeFromFeeds', false),
          new EntityProp('enableSlido', false),
          new EntityProp(
            'slidoEventUrl',
            '',
            [validateRequired, validateUrl],
            null,
            true,
          ),
          new EntityProp('prefillSlidoCredentials', true),
          new EntityProp('slidoTabHeader', 'Ask A Question'),
          new EntityProp('showSlidoBeforeWebcast', true),
          new EntityProp('showSlidoDuringWebcast', true),
          new EntityProp('showSlidoAfterWebcast', false),
          new EntityProp('wvLiveStreamId', null),
          new EntityProp('streamingProtocol', 'rtmp'),
          new EntityProp('enableWowzaVideoAutomation', true),
          new EntityProp('srtIngestLocation', 'eu_germany'),
          new EntityProp('wvOrgId', 'vccopen'),
          new EntityProp(
            'hlsPublicLiveUrl',
            '',
            [validateRequired, validateUrl],
            null,
            true,
          ),
          new EntityProp('wvStreamIsLive', true),
          new EntityProp(
            'hlsPublicVodUrl',
            '',
            [validateRequired, validateUrl],
            null,
            true,
          ),
          new EntityProp(
            'hlsOnPremRelativeUrl',
            '',
            [validateRequired, validateUrl],
            null,
            true,
          ),
        ],
        current,
      );
      return _metaData;
    },
    [getOrgUserProfile],
  );

  const globalSettings = getGlobalSettings();

  const { classes } = useStyles();
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [myRoles, setMyRoles] = useState([]);
  const [canEdit, setCanEdit] = useState(false);

  const loadMetadataCb = useCallback(
    async (metadata: any, setPermissions: any) => {
      // Add location technicians to the webcast's permission list, to make it clear
      // in the UI that those people also have access to the webcast
      if (
        metadata.location &&
        metadata.location.technicians &&
        metadata.location.technicians.length > 0
      ) {
        const updatedPerms = [...metadata.permissions].concat(
          metadata.location.technicians.map((t: any) => {
            return {
              id: t.userId,
              permissionLevel: 2,
              user: {
                fullName: t.user.fullName,
              },
              isInheritedTechnician: true,
              inheritedTechnicianLocation: metadata.location.title,
            };
          }),
        );
        setPermissions(updatedPerms);
      }
    },
    [],
  );

  const isNewRequest = itemId === 'new';

  const canSave = metadata.isDeleted.value === false;

  const handleCheckedChange = (
    event: any,
    value: any,
    performDependencyValidation?: any,
    ...dependentControlNames: any[]
  ) => {
    const updateColl = {
      [event.target.name]: {
        value: value,
        isValid: true,
        validators: metadata[event.target.name].validators,
      },
    };
    dependentControlNames.forEach((name: any, index) => {
      const val = metadata[name].value;

      updateColl[name] = {
        value: val,
        isValid:
          performDependencyValidation === false ||
          isNewRequest ||
          (val && val.length > 0)
            ? true
            : false,
        validators: metadata[name].validators,
      };
    });

    setMetadata({ ...metadata, ...updateColl });
  };

  const handleDateTimeChange = (targetName: any, newValue: any) => {
    // If this is a request only, fields are allowed to be empty.
    if (isNewRequest && (!newValue || newValue.length === 0)) {
      setMetadata({
        ...metadata,
        [targetName]: {
          value: isNewRequest,
          isValid: true,
        },
      });
      return;
    }
    // If this is an existing webcast or a value is entered, all date time fields must be validated as a group...
    let startTimeToCheck,
      endTimeToCheck = null;
    switch (targetName) {
      case 'startTime':
        startTimeToCheck = newValue;
        endTimeToCheck = metadata.endTime.value;
        break;
      case 'endTime':
        startTimeToCheck = metadata.startTime.value;
        endTimeToCheck = newValue;
        break;
      default:
        break;
    }
    const timesMatch = startTimeToCheck < endTimeToCheck;
    setMetadata({
      ...metadata,
      startTime: {
        value: startTimeToCheck,
        isValid:
          timesMatch === true && (isNewRequest || startTimeToCheck !== null),
      },
      endTime: {
        value: endTimeToCheck,
        isValid:
          timesMatch === true && (isNewRequest || endTimeToCheck !== null),
      },
    });
  };

  const handleCustomCDSwichOverTimeChange = (newValue: Date | null) => {
    setMetadata((prevState: any) => {
      return {
        ...prevState,
        customCDSwitchOverTime: {
          value: newValue,
          isValid: true,
        },
      };
    });
  };

  const getSRTIngestLocations = () => {
    return [
      { id: 'asia_pacific_australia', title: 'Australia' },
      { id: 'eu_belgium', title: 'Belgium' },
      { id: 'south_america_brazil', title: 'Brazil' },
      { id: 'us_west_california', title: 'California' },
      { id: 'eu_germany', title: 'Germany' },
      { id: 'asia_pacific_india', title: 'India' },
      { id: 'eu_ireland', title: 'Ireland' },
      { id: 'us_central_iowa', title: 'Iowa' },
      { id: 'asia_pacific_japan', title: 'Japan' },
      { id: 'asia_pacific_s_korea', title: 'South Korea' },
      { id: 'us_east_s_carolina', title: 'South Carolina' },
      { id: 'asia_pacific_singapore', title: 'Singapore' },
      { id: 'asia_pacific_taiwan', title: 'Taiwan' },
      { id: 'us_west_oregon', title: 'Oregon' },
      { id: 'us_east_virginia', title: 'Virginia' },
    ];
  };

  const getWowzaVideoOrgazations = () => {
    return [
      { id: 'vccopen', title: 'Volvo Cars Open' },
      { id: 'vccconf', title: 'Volvo Cars Confidential' },
      { id: 'ps', title: 'Polestar' },
      { id: 'vcs', title: 'Volvo Cars Sverige' },
    ];
  };

  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} index={0} key="panel_0" mode="tabs">
          <TextField
            fullWidth
            id="title"
            name="title"
            label="Title part 1 (black)"
            value={metadata.title.value}
            error={!metadata.title.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit || !canSave}
            inputProps={{
              maxLength: 100,
            }}
            required
          />
          <TextField
            fullWidth
            id="title2"
            name="title2"
            label="Title part 2 (grey)"
            value={metadata.title2 ? metadata.title2.value : ''}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit || !canSave}
            inputProps={{
              maxLength: 100,
            }}
          />
          <TextField
            fullWidth
            id="contactEmail"
            name="contactEmail"
            label="Contact Email Address"
            value={metadata.contactEmail.value}
            error={!metadata.contactEmail.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit || !canSave}
            inputProps={{
              maxLength: 256,
            }}
            helperText={
              metadata.contactEmail.isValid === false
                ? 'Enter a valid email address.'
                : ''
            }
          />
          <Grid2>
            <DateTimePicker
              slotProps={{
                textField: {
                  helperText:
                    metadata.startTime.value === null ||
                    metadata.startTime.value === ''
                      ? 'Start time is required.'
                      : metadata.startTime.isValid === false
                        ? 'Verify that dates are in correct order.'
                        : '',
                  className:
                    metadata.startTime.isValid === false ||
                    metadata.startTime.value === null ||
                    metadata.startTime.value === ''
                      ? classes.dtError
                      : '',
                  error:
                    metadata.startTime.isValid === false ||
                    metadata.startTime.value === null ||
                    metadata.startTime.value === '',
                  id: 'startTime',
                  name: 'startTime',
                  required: true,
                },
              }}
              value={metadata.startTime.value ? metadata.startTime.value : null}
              label="Start time"
              disabled={!canEdit || !canSave}
              onChange={(newValue) => {
                handleDateTimeChange('startTime', newValue);
              }}
            />
            <DateTimePicker
              slotProps={{
                textField: {
                  helperText:
                    metadata.endTime.value === null ||
                    metadata.endTime.value === ''
                      ? 'End time is required.'
                      : metadata.endTime.isValid === false
                        ? 'Verify that dates are in correct order.'
                        : '',
                  className:
                    metadata.endTime.isValid === false ||
                    metadata.endTime.value === null ||
                    metadata.endTime.value === ''
                      ? classes.dtError
                      : '',
                  error:
                    metadata.endTime.isValid === false ||
                    metadata.endTime.value === null ||
                    metadata.endTime.value === '',
                  id: 'endTime',
                  name: 'endTime',
                  required: true,
                },
              }}
              value={metadata.endTime.value ? metadata.endTime.value : null}
              onChange={(newValue) => {
                handleDateTimeChange('endTime', newValue);
              }}
              disabled={!canEdit || !canSave}
              label="End time"
            />
          </Grid2>
          <FormControl>
            <Grid2
              className={classes.dependentSelects}
              container
              spacing={2}
              alignItems="center"
            >
              <LazyAutocomplete
                initialValue={metadata.region?.value}
                label="Region"
                error={metadata.regionId.isValid === false}
                onNavigate={(item: { id: any }) =>
                  window.open(
                    `${Constants.routes.adminCenter.regions}/${item.id}`,
                  )
                }
                disabled={!canEdit || !canSave}
                apiUrl={Constants.routes.api.regions}
                metadata={metadata}
                setMetadata={setMetadata}
                idColumnName="regionId"
                valColumnName="region"
                required
              />
              <LazyAutocomplete
                initialValue={metadata.location?.value}
                label="Location"
                error={metadata.locationId.isValid === false}
                onNavigate={(item: { id: any }) =>
                  window.open(
                    `${Constants.routes.adminCenter.locations}/${item.id}`,
                  )
                }
                disabled={!canEdit || !metadata.region?.value || !canSave}
                apiUrl={Constants.routes.api.locations}
                metadata={metadata}
                setMetadata={setMetadata}
                idColumnName="locationId"
                valColumnName="location"
                filterprop="regionId"
                filterval={metadata.region.value?.id}
                required
              />
              <LazyAutocomplete
                initialValue={metadata.channel?.value}
                label="Channel"
                error={metadata.channelId.isValid === false}
                onNavigate={(item: { id: any }) =>
                  window.open(
                    `${Constants.routes.adminCenter.channels}/${item.id}`,
                  )
                }
                disabled={!canEdit || !metadata.region?.value || !canSave}
                apiUrl={Constants.routes.api.channels}
                metadata={metadata}
                setMetadata={setMetadata}
                idColumnName="channelId"
                valColumnName="channel"
                filterprop="regionId"
                filterval={metadata.region.value?.id}
              />
            </Grid2>
          </FormControl>
        </TabPanel>
      ),
    },
    {
      label: 'Video',
      components: (
        <TabPanel value={activeTab} index={1} key="panel_1" mode="tabs">
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <>
              <FormLabel>Enable Wowza Video automation</FormLabel>
              <RadioGroup
                aria-label="enableWowzaVideoAutomation"
                value={metadata.enableWowzaVideoAutomation.value}
                name="enableWowzaVideoAutomation"
                onChange={(event) => {
                  const boolVal = event.target.value === 'true' ? true : false;
                  const updateColl = {
                    [event.target.name]: {
                      value: boolVal,
                      isValid: true,
                    },
                  };

                  setMetadata({ ...metadata, ...updateColl });
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio disabled={!canEdit || !canSave} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio disabled={!canEdit || !canSave} />}
                  label="No"
                />
              </RadioGroup>
              {metadata.enableWowzaVideoAutomation.value === true ? (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="srtIngestLocation">
                      SRT Ingest Location
                    </InputLabel>
                    <Select
                      name="srtIngestLocation"
                      id="srtIngestLocation-select"
                      value={metadata.srtIngestLocation.value}
                      label="SRT Ingest Location"
                      onChange={(event) => {
                        const updateCol = {
                          [event.target.name]: {
                            value: event.target.value,
                            isValid: true,
                          },
                        };
                        setMetadata({ ...metadata, ...updateCol });
                      }}
                    >
                      {getSRTIngestLocations().map(
                        (location: any, index: any) => (
                          <MenuItem
                            key={`location-${index}`}
                            value={location.id}
                          >
                            {location.title}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="wvOrg">WOWZA Video Organization</InputLabel>
                    <Select
                      name="wvOrgId"
                      id="wvOrg-select"
                      value={metadata.wvOrgId.value}
                      label="WOWZA Video Organization"
                      onChange={(event) => {
                        const updateCol = {
                          [event.target.name]: {
                            value: event.target.value,
                            isValid: true,
                          },
                        };
                        setMetadata({ ...metadata, ...updateCol });
                      }}
                    >
                      {getWowzaVideoOrgazations().map(
                        (organization: any, index: any) => (
                          <MenuItem
                            key={`organization-${index}`}
                            value={organization.id}
                          >
                            {organization.title}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <TextField
                    fullWidth
                    id="hlsPublicLiveUrl"
                    name="hlsPublicLiveUrl"
                    label="Wowza Video public live HLS URL"
                    value={metadata.hlsPublicLiveUrl.value}
                    error={metadata.hlsPublicLiveUrl.isValid === false}
                    onChange={(event) => {
                      setMetadata(validateMetadata(metadata, event));
                    }}
                    disabled={!canEdit || !canSave}
                    helperText={
                      <Typography
                        variant="body2"
                        color={
                          metadata.hlsPublicLiveUrl.isValid === false
                            ? '#d32f2f'
                            : 'textSecondary'
                        }
                        dangerouslySetInnerHTML={{
                          __html: `${
                            metadata.hlsPublicLiveUrl.isValid === false
                              ? 'Please give a valid url'
                              : ''
                          }`,
                        }}
                      />
                    }
                  />
                  <FormLabel>Wowza Video is live</FormLabel>
                  <RadioGroup
                    aria-label=""
                    value={metadata.wvStreamIsLive.value}
                    name="wvStreamIsLive"
                    onChange={(event) => {
                      const boolVal =
                        event.target.value === 'true' ? true : false;
                      const updateColl = {
                        [event.target.name]: {
                          value: boolVal,
                          isValid: true,
                        },
                      };

                      setMetadata({ ...metadata, ...updateColl });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio disabled={!canEdit || !canSave} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio disabled={!canEdit || !canSave} />}
                      label="No"
                    />
                  </RadioGroup>
                  <TextField
                    fullWidth
                    id="hlsPublicVodUrl"
                    name="hlsPublicVodUrl"
                    label="Wowza Video public VOD HLS URL"
                    value={metadata.hlsPublicVodUrl.value}
                    error={metadata.hlsPublicVodUrl.isValid === false}
                    onChange={(event) => {
                      setMetadata(validateMetadata(metadata, event));
                    }}
                    disabled={!canEdit || !canSave}
                    helperText={
                      <Typography
                        variant="body2"
                        color={
                          metadata.hlsPublicVodUrl.isValid === false
                            ? '#d32f2f'
                            : 'textSecondary'
                        }
                        dangerouslySetInnerHTML={{
                          __html: `${
                            metadata.hlsPublicVodUrl.isValid === false
                              ? 'Please give a valid url'
                              : ''
                          }`,
                        }}
                      />
                    }
                  />
                  <TextField
                    fullWidth
                    id="hlsOnPremRelativeUrl"
                    name="hlsOnPremRelativeUrl"
                    label="Wowza Video on prem relative HLS URL"
                    value={metadata.hlsOnPremRelativeUrl.value}
                    error={metadata.hlsOnPremRelativeUrl.isValid === false}
                    onChange={(event) => {
                      setMetadata(validateMetadata(metadata, event));
                    }}
                    disabled={!canEdit || !canSave}
                    helperText={
                      <Typography
                        variant="body2"
                        color={
                          metadata.hlsOnPremRelativeUrl.isValid === false
                            ? '#d32f2f'
                            : 'textSecondary'
                        }
                        dangerouslySetInnerHTML={{
                          __html: `${
                            metadata.hlsOnPremRelativeUrl.isValid === false
                              ? 'Please give a valid url'
                              : ''
                          }`,
                        }}
                      />
                    }
                  />
                </>
              )}
            </>
          </FormControl>
        </TabPanel>
      ),
    },
    {
      label: 'Presentation',
      components: (
        <TabPanel value={activeTab} index={2} key="panel_2" mode="tabs">
          <Grid2 container spacing={2} alignItems="top">
            <Grid2>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'webcast-thumb',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.customThumbnailUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'customThumbnailUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Thumbnail image"
                defaultImageUrl={
                  getHostDependentImageUrl(
                    getDefaultThumbnailFromParent(metadata.channel?.value),
                  ) ||
                  getHostDependentImageUrl(
                    getDefaultThumbnailFromParent(metadata.region?.value),
                  )
                }
                descriptiveText="This image is used as thumbnail, as video player background image and in embedded content."
                disabled={!canEdit || !canSave}
                parentType={metadata.channel?.value ? 'channel' : 'region'}
                parentName={
                  metadata.channel?.value
                    ? metadata.channel?.value?.title
                    : metadata.region?.value?.title
                }
              />
            </Grid2>
            <Grid2>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'webcast-hero',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.customHeroUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'customHeroUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Hero image"
                defaultImageUrl={
                  getHostDependentImageUrl(
                    getDefaultHeroFromParent(metadata.channel?.value),
                  ) ||
                  getHostDependentImageUrl(
                    getDefaultHeroFromParent(metadata.region?.value),
                  )
                }
                descriptiveText="This image is used as hero (in the page header) as background for the countdown clock"
                disabled={!canEdit || !canSave}
                parentType={metadata.channel?.value ? 'channel' : 'region'}
                parentName={
                  metadata.channel?.value
                    ? metadata.channel?.value?.title
                    : metadata.region?.value?.title
                }
              />
            </Grid2>
          </Grid2>

          <TextField
            fullWidth
            multiline
            rows={4}
            id="description"
            name="description"
            label="Webcast description"
            value={metadata.description.value}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            error={!metadata.description.isValid}
            disabled={!canEdit || !canSave}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            dangerouslySetInnerHTML={{
              __html: `The description appears on the video page.
                <br/>Supports simple <a href = "https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">Markdown</a> formatting.
                <br/><ul><li>Bold text: Use **double asterisk** = Use double asterisk</li>
                <li>Links: Are clickable, ex https://www.volvocars.com = <a href="https://www.volvocars.com">https://www.volvocars.com</a></li>
                <li>Named links: [VCC website](https://www.volvocars.com) = <a href="https://www.volvocars.com/">VCC website</a></li>
                <li>Line breaks: Use ENTER for line breaks'</li></ul>`,
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={metadata.descriptionFieldContainsHTML.value}
                onChange={(event, value) => handleCheckedChange(event, value)}
                name="descriptionFieldContainsHTML"
                color="primary"
                disabled={!canEdit || !canSave}
              />
            }
            label="Allow HTML tags in webcast description"
          />
          <TextField
            fullWidth
            multiline
            id="lobbyDescription"
            name="lobbyDescription"
            label="Countdown page description"
            value={metadata.lobbyDescription.value}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            helperText="Shown on the countdown page. If left empty, the webcast description will be used."
            disabled={!canEdit || !canSave}
          />
          <FormControlLabel
            control={
              <Switch
                checked={metadata.lobbyDescriptionFieldContainsHTML.value}
                onChange={(event, value) => handleCheckedChange(event, value)}
                name="lobbyDescriptionFieldContainsHTML"
                color="primary"
                disabled={!canEdit || !canSave}
              />
            }
            label="Allow HTML in countdown page description"
          />
        </TabPanel>
      ),
    },
    {
      label: 'Security',
      components: (
        <TabPanel value={activeTab} index={3} key="panel_3" mode="tabs">
          <FormControl component="fieldset">
            <FormLabel>Audience:</FormLabel>
            <RadioGroup
              aria-label="requireOrgSignIn"
              value={metadata.requireOrgSignIn.value ? 1 : 0}
              name="requireOrgSignIn"
              onChange={(event) => {
                const boolVal =
                  parseInt(event.target.value) === 1 ? true : false;
                const updateColl = {
                  [event.target.name]: {
                    value: boolVal,
                    isValid: true,
                  },
                };

                // Reset the security options every time the audience changes
                if (event.target.name === 'requireOrgSignIn') {
                  updateColl['allowExternalEmailSignin'] = {
                    value: false,
                    isValid: true,
                  };
                  updateColl['requireInvite'] = {
                    value: false,
                    isValid: true,
                  };
                  updateColl['requirePin'] = {
                    value: false,
                    isValid: true,
                  };
                }

                setMetadata({ ...metadata, ...updateColl });
              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio disabled={!canEdit || !canSave} />}
                label={globalSettings?.organization?.title + ' users only'}
              />
              <FormControlLabel
                value={0}
                control={<Radio disabled={!canEdit || !canSave} />}
                label="External users"
              />
            </RadioGroup>
          </FormControl>
          <Grid2>
            {metadata.requireOrgSignIn.value === false && (
              <FormControlLabel
                control={
                  <Switch
                    checked={metadata.allowExternalEmailSignin.value}
                    onChange={(event, value) => {
                      const updateColl = {
                        [event.target.name]: {
                          value: value,
                          isValid: true,
                        },
                      };
                      // Reset the security options every time the audience changes
                      updateColl['requireInvite'] = {
                        value: false,
                        isValid: true,
                      };
                      setMetadata({ ...metadata, ...updateColl });
                    }}
                    name="allowExternalEmailSignin"
                    color="primary"
                    disabled={!canEdit || !canSave}
                  />
                }
                label="Require email registration for external users"
              />
            )}
          </Grid2>
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.requireInvite.value}
                  onChange={(event, value) => handleCheckedChange(event, value)}
                  name="requireInvite"
                  color="primary"
                  disabled={
                    !canEdit ||
                    (metadata.requireOrgSignIn.value === false &&
                      metadata.allowExternalEmailSignin.value === false) ||
                    !canSave
                  }
                />
              }
              label="Invited users only"
            />
          </Grid2>
          {metadata.requireInvite.value && (
            <Grid2>
              <Typography variant="body2" sx={{ ml: 2, mb: 2 }}>
                Add users under the <strong>Permissions</strong> tab.
              </Typography>
            </Grid2>
          )}
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.requirePin.value}
                  onChange={(event, value) => handleCheckedChange(event, value)}
                  name="requirePin"
                  color="primary"
                  disabled={!canEdit || !canSave}
                />
              }
              label="Use PIN code for access"
            />
          </Grid2>
        </TabPanel>
      ),
    },
    {
      label: 'Call-to-action',
      components: (
        <TabPanel value={activeTab} index={4} key="panel_4" mode="tabs">
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.enableCTAButton.value}
                  onChange={(event, value) =>
                    handleCheckedChange(
                      event,
                      value,
                      value === true,
                      'ctaButtonHeadline',
                      'ctaButtonTitle',
                      'ctaButtonUrl',
                    )
                  }
                  name="enableCTAButton"
                  color="primary"
                  disabled={!canEdit || !canSave}
                />
              }
              label="Enable Call-to-Action Button"
            />
          </Grid2>
          {metadata.enableCTAButton.value === true && (
            <Grid2>
              <TextField
                id="ctaButtonUrl"
                name="ctaButtonUrl"
                label="CTA button link"
                value={metadata.ctaButtonUrl.value}
                error={metadata.ctaButtonUrl.isValid === false}
                onChange={(event) =>
                  setMetadata(
                    validateMetadata(
                      metadata,
                      event,
                      metadata.enableCTAButton.value === true && !isNewRequest,
                    ),
                  )
                }
                helperText={
                  <Typography
                    variant="body2"
                    color={
                      metadata.ctaButtonUrl.isValid === false
                        ? '#d32f2f'
                        : 'textSecondary'
                    }
                    dangerouslySetInnerHTML={{
                      __html: `
                  ${
                    metadata.ctaButtonUrl.isValid === false
                      ? '<br/>Please give a valid url.<br/><br/>Opens in new tab/window when CTA button is clicked. Example: "https://www.volvocars.com"'
                      : 'Opens in new tab/window when CTA button is clicked. Example: "https://www.volvocars.com"'
                  }`,
                    }}
                  ></Typography>
                }
                disabled={!canEdit || !canSave}
              />
              <TextField
                id="ctaButtonHeadline"
                name="ctaButtonHeadline"
                label="CTA button headline"
                value={metadata.ctaButtonHeadline.value}
                error={metadata.ctaButtonHeadline.isValid === false}
                onChange={(event) =>
                  setMetadata(validateMetadata(metadata, event))
                }
                helperText='i.e "Want to know more?"'
                disabled={!canEdit || !canSave}
              />
              <TextField
                fullWidth
                id="ctaButtonDescription"
                name="ctaButtonDescription"
                label="CTA button description"
                value={metadata.ctaButtonDescription.value}
                error={metadata.ctaButtonDescription.isValid === false}
                onChange={(event) =>
                  setMetadata(validateMetadata(metadata, event))
                }
                helperText={
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html:
                        'The description appears next to the CTA button.<br/>Supports simple Markdown formatting:<br/>Bold text: Use ** double asterisk** = Use <b>double asterisk</b>',
                    }}
                  />
                }
                disabled={!canEdit || !canSave}
              />
              <TextField
                id="ctaButtonTitle"
                name="ctaButtonTitle"
                label="Text on CTA button"
                defaultValue={'VIEW'}
                value={metadata.ctaButtonTitle.value}
                error={metadata.ctaButtonTitle.isValid === false}
                onChange={(event) =>
                  setMetadata(
                    validateMetadata(
                      metadata,
                      event,
                      metadata.enableCTAButton.value === true && !isNewRequest,
                    ),
                  )
                }
                helperText='i.e "GO"'
                disabled={!canEdit || !canSave}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={metadata.showCTAButtonInVod.value}
                    onChange={(event, value) =>
                      handleCheckedChange(event, value)
                    }
                    name="showCTAButtonInVod"
                    color="primary"
                    disabled={!canEdit || !canSave}
                  />
                }
                label="Show Call-to-Action also during recorded webcast"
              />
            </Grid2>
          )}
        </TabPanel>
      ),
    },
    {
      label: 'Slido',
      components: (
        <TabPanel value={activeTab} index={5} key="panel_5" mode="tabs">
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.enableSlido.value}
                  onChange={(event, value) => handleCheckedChange(event, value)}
                  name="enableSlido"
                  color="primary"
                  disabled={!canEdit || !canSave}
                />
              }
              label="Enable Slido"
            />
          </Grid2>
          {metadata.enableSlido.value === true && (
            <Grid2>
              <TextField
                fullWidth
                id="slidoEventUrl"
                name="slidoEventUrl"
                label="Slido event link"
                value={metadata.slidoEventUrl.value}
                error={!metadata.slidoEventUrl.isValid}
                onChange={(event) =>
                  setMetadata(validateMetadata(metadata, event))
                }
                helperText={
                  <Typography
                    variant="body2"
                    color={
                      metadata.slidoEventUrl.isValid === false
                        ? '#d32f2f'
                        : 'textSecondary'
                    }
                    dangerouslySetInnerHTML={{
                      __html: `${
                        metadata.slidoEventUrl.isValid === false
                          ? 'Please give a valid url.<br/><br/>i.e "https://app.sli.do/event/7q0zb4jq". <br/>You can navigate your participants directly to Questions, Ideas or Polls and Surveys if you have multiple menu items available in your app. Just add /questions, /polls, or /ideas to your event link to do so. Read the full guide to Slido integration <a href = "https://community.slido.com/embedding-slido-68/embed-your-slido-event-408">here</a>.'
                          : 'i.e "https://app.sli.do/event/7q0zb4jq". <br/>You can navigate your participants directly to Questions, Ideas or Polls and Surveys if you have multiple menu items available in your app. Just add /questions, /polls, or /ideas to your event link to do so. Read the full guide to Slido integration <a href = "https://community.slido.com/embedding-slido-68/embed-your-slido-event-408">here</a>.'
                      }`,
                    }}
                  />
                }
                disabled={!canEdit || !canSave}
              />
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={metadata.prefillSlidoCredentials.value}
                      onChange={(event, value) =>
                        handleCheckedChange(event, value)
                      }
                      name="prefillSlidoCredentials"
                      color="primary"
                      disabled={!canEdit || !canSave}
                    />
                  }
                  label="Pre-fill Slido user data"
                />
              </div>
              <TextField
                fullWidth
                id="slidoTabHeader"
                name="slidoTabHeader"
                label="Slido tab header text"
                defaultValue="Ask a question"
                value={metadata.slidoTabHeader.value}
                error={!metadata.slidoTabHeader.isValid}
                onChange={(event) =>
                  setMetadata(validateMetadata(metadata, event))
                }
                disabled={!canEdit || !canSave}
              />
              <Grid2>
                <FormLabel>When to show the Slido event:</FormLabel>
                <Grid2>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={metadata.showSlidoBeforeWebcast.value}
                        onChange={(event, value) =>
                          handleCheckedChange(event, value)
                        }
                        name="showSlidoBeforeWebcast"
                        color="primary"
                        disabled={!canEdit || !canSave}
                      />
                    }
                    label="Before Webcast"
                  />
                </Grid2>
                <Grid2>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={metadata.showSlidoDuringWebcast.value}
                        onChange={(event, value) =>
                          handleCheckedChange(event, value)
                        }
                        name="showSlidoDuringWebcast"
                        color="primary"
                        disabled={!canEdit || !canSave}
                      />
                    }
                    label="During Webcast"
                  />
                </Grid2>
                <Grid2>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={metadata.showSlidoAfterWebcast.value}
                        onChange={(event, value) =>
                          handleCheckedChange(event, value)
                        }
                        name="showSlidoAfterWebcast"
                        color="primary"
                        disabled={!canEdit || !canSave}
                      />
                    }
                    label="After Webcast"
                  />
                </Grid2>
              </Grid2>
            </Grid2>
          )}
        </TabPanel>
      ),
    },
    {
      label: 'Advanced',
      components: (
        <TabPanel value={activeTab} index={6} key="panel_6" mode="tabs">
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.allowEmbed.value}
                  onChange={(event, value) => handleCheckedChange(event, value)}
                  name="allowEmbed"
                  color="primary"
                  disabled={!canEdit || !canSave}
                />
              }
              label="Allow embed"
            />
          </Grid2>
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.excludeFromFeeds.value}
                  onChange={(event, value) => handleCheckedChange(event, value)}
                  name="excludeFromFeeds"
                  color="primary"
                  disabled={!canEdit || !canSave}
                />
              }
              label="Exclude from landing page, galleries and other lists"
            />
          </Grid2>
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.hideStatistics.value}
                  onChange={(event, value) => handleCheckedChange(event, value)}
                  name="hideStatistics"
                  color="primary"
                  disabled={!canEdit || !canSave}
                />
              }
              label="Hide Statistics"
            />
          </Grid2>
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    metadata.useCustomCDSwitchOver != null &&
                    metadata.useCustomCDSwitchOver.value
                  }
                  onChange={(event, value) => {
                    handleCheckedChange(event, value);
                    if (value === false) {
                      handleCustomCDSwichOverTimeChange(null);
                    }
                  }}
                  name="useCustomCDSwitchOver"
                  color="primary"
                  disabled={!canEdit || !canSave}
                />
              }
              label="Use custom countdown switchover time"
            />
            {metadata.useCustomCDSwitchOver &&
              metadata.useCustomCDSwitchOver.value && (
                <DateTimePicker
                  slotProps={{
                    textField: {
                      helperText:
                        metadata.customCDSwitchOverTime.isValid === false
                          ? 'Verify that date is correct.'
                          : '',
                      className:
                        metadata.customCDSwitchOverTime.isValid === false
                          ? classes.dtError
                          : '',
                      error:
                        metadata.customCDSwitchOverTime &&
                        metadata.customCDSwitchOverTime.isValid === false,
                      id: 'customCDSwitchOverTime',
                      name: 'customCDSwitchOverTime',
                    },
                  }}
                  value={
                    metadata.customCDSwitchOverTime &&
                    metadata.customCDSwitchOverTime.value
                      ? metadata.customCDSwitchOverTime.value
                      : null
                  }
                  onChange={handleCustomCDSwichOverTimeChange}
                  disabled={!canEdit || !canSave}
                />
              )}
          </Grid2>
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <FormLabel>After webcast has ended:</FormLabel>
            <RadioGroup
              aria-label="postEventBehavior"
              value={metadata.postEventBehavior.value}
              name="postEventBehavior"
              onChange={(event) =>
                handleCheckedChange(
                  event,
                  parseInt(event.target.value),
                  parseInt(event.target.value) === 2,
                  'redirectUrl',
                )
              }
            >
              <FormControlLabel
                value={0}
                control={<Radio disabled={!canEdit || !canSave} />}
                label="Show 'Event has ended' message"
              />
              <FormControlLabel
                value={2}
                control={<Radio disabled={!canEdit || !canSave} />}
                label="Redirect to another web page"
              />
              {parseInt(metadata.postEventBehavior.value) === 2 && (
                <TextField
                  fullWidth
                  id="redirectUrl"
                  name="redirectUrl"
                  label="Redirect to (URL)"
                  value={metadata.redirectUrl.value}
                  error={metadata.redirectUrl.isValid === false}
                  onChange={(event) =>
                    setMetadata(
                      validateMetadata(
                        metadata,
                        event,
                        parseInt(metadata.postEventBehavior.value) === 2 &&
                          !isNewRequest,
                      ),
                    )
                  }
                  disabled={!canEdit || !canSave}
                  helperText={
                    metadata.redirectUrl.isValid === false
                      ? 'Please give a valid url.'
                      : ''
                  }
                />
              )}
              <FormControlLabel
                value={1}
                control={<Radio disabled={!canEdit || !canSave} />}
                label="Show webcast recording"
              />
            </RadioGroup>
          </FormControl>
        </TabPanel>
      ),
    },
  ];

  return (
    <TabbedEditor
      entityName="Webcast"
      apiUrl={Constants.routes.api.webcasts}
      entityRoute={Constants.routes.adminCenter.webcasts}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      setMetadata={setMetadata}
      canEdit={canEdit}
      canDelete={canEdit}
      canEditPermissions={canEdit}
      setCanEdit={setCanEdit}
      canSave={canSave}
      usePermissions
      initMetadataFn={initMetadata}
      entityId={itemId}
      myRoles={myRoles}
      setMyRoles={setMyRoles}
      loadMetadataCb={loadMetadataCb}
    />
  );
}
