import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LookupLink from '../sharedComponents/lookupLink';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import { AppType, Constants, useLocalStorage } from 'vcl-common';

export default function Locations() {
  const { getAuthResult } = useLocalStorage();
  const userProfile = getAuthResult(AppType.AdminWeb)?.userProfile;

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = [
    { field: 'title', headerName: 'Title', width: 300, sortable: false },
    { field: 'locality', headerName: 'Locality', width: 300, sortable: false },
    {
      field: 'region',
      headerName: 'Region',
      width: 300,
      sortable: false,
      valueFormatter: (params: { title: any | null }) => {
        return params && params.title ? params.title : '';
      },
      renderCell: (params: { value: { id: any; title: any } | null }) => {
        if (params && params.value) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.regions}/${params.value.id}`}
              displayText={params.value.title ?? ''}
            />
          );
        } else return null;
      },
      disableClickEventBubbling: true,
      valueParser: (value: any) => value,
      hide: isXSmallScreen,
    },
  ];

  return (
    <VCLDataGrid
      title="Locations"
      entityRoute={Constants.routes.adminCenter.locations}
      apiUrl={Constants.routes.api.locations}
      getPersonalizedData={false}
      gridColumns={columns}
      canUserAdd={userProfile?.isRegionAdminOfAny ?? false}
      canUserViewGrid={userProfile?.isRegionAdminOfAny ?? false}
    />
  );
}
