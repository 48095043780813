import VCLDataGrid from '../sharedComponents/vclDataGrid';
import { AppType, Constants, useLocalStorage } from 'vcl-common';

export default function Users() {
  const { getAuthResult } = useLocalStorage();
  const userProfile = getAuthResult(AppType.AdminWeb)?.userProfile;

  const columns = [
    { field: 'userName', headerName: 'Full Name', width: 300, sortable: false },
    { field: 'fullName', headerName: 'User Name', width: 300, sortable: false },
    {
      field: 'identityProviderString',
      headerName: 'Identity Provider',
      width: 300,
      sortable: false,
    },
  ];

  return (
    <VCLDataGrid
      title="Users"
      apiUrl={Constants.routes.api.users}
      entityRoute={Constants.routes.adminCenter.users}
      getPersonalizedData={false}
      gridColumns={columns}
      canUserAdd={userProfile?.isGlobalAdmin ?? false}
      canUserViewGrid={userProfile?.isGlobalAdmin ?? false}
    />
  );
}
