import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { useState, useMemo, useEffect } from 'react';
import {
  Constants,
  useLocalStorage,
  IAuthResult,
  IPageMetadata,
  VclApiGetType,
  HTTP_METHODS,
  useVclApi,
  VclApiProps,
  AppType,
} from 'vcl-common';
import AdminCenter from './AdminCenter';
import { loginRequest } from './utils/msalConfig';
import { AxiosResponse } from 'axios';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    chipGrey?: {
      main: string;
      light: string;
      dark?: string;
      contrastText?: string;
    };
  }
}

const theme: any = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3D5881',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#68875C',
    },
    // error: {
    //   main: '#A13432',
    // },
    warning: {
      main: '#BF834B',
    },
    info: {
      main: '#878685',
    },
    success: {
      main: '#68875C',
    },
    chipGrey: {
      main: '#222222',
      light: '#888888',
      dark: '#090909',
      contrastText: '#DDDDDD',
    },
  },
});

export default function App() {
  const { setAuthResult, setGlobalSettings } = useLocalStorage();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(true);

  const apiParams = useMemo<Record<string, any>>(() => {
    return { url: window.location.href };
  }, []);

  const authResultProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.authOrgAdmin,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
      fetchImmediately: true,
      params: apiParams,
    };
  }, [apiParams]);
  const {
    apiResponse: authResultResponse,
    apiRequestInProgress: authResultInProgress,
    apiError: authResultError,
  } = useVclApi<IAuthResult>(authResultProps);

  const metadataProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.pageMetadata,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Item,
      useMsalAuthorization: false,
      params: apiParams,
    };
  }, [apiParams]);
  const { callApi: getMetadata, apiError: metadataError } =
    useVclApi<IPageMetadata>(metadataProps);

  useEffect(() => {
    (async () => {
      if (
        loading &&
        !authResultInProgress &&
        authResultResponse?.data &&
        !authResultError?.isError
      ) {
        setAuthResult(authResultResponse?.data, AppType.AdminWeb);
        const response = (await getMetadata()) as AxiosResponse;
        const pageMetadata = response.data as IPageMetadata;
        if (pageMetadata?.globalSettings) {
          setGlobalSettings(pageMetadata.globalSettings);
        }
        setLoading(false);
      }
    })();
  }, [
    authResultResponse,
    authResultError?.isError,
    authResultInProgress,
    getMetadata,
    loading,
    setAuthResult,
    setGlobalSettings,
  ]);

  useEffect(() => {
    if (authResultError?.isError) {
      setError(true);
      setErrorMessage(authResultError.errorMessage);
    } else if (metadataError?.isError) {
      setError(true);
      setErrorMessage(metadataError.errorMessage);
    }
  }, [authResultError, metadataError]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AdminCenter
          loading={loading}
          error={error}
          errorMessage={errorMessage}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
