import VCLDataGrid from '../sharedComponents/vclDataGrid';
import { AppType, Constants, useLocalStorage } from 'vcl-common';

export default function Configuration() {
  const { getAuthResult } = useLocalStorage();
  const userProfile = getAuthResult(AppType.AdminWeb)?.userProfile;

  const columns = [
    {
      field: 'key',
      headerName: 'Key',
      width: 300,
      sortable: false,
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 300,
      sortable: false,
    },
  ];

  return (
    <VCLDataGrid
      title="Global Configuration"
      entityRoute={Constants.routes.adminCenter.globalConfig}
      apiUrl={Constants.routes.api.globalConfig}
      getPersonalizedData={false}
      gridColumns={columns}
      canUserAdd={userProfile?.isGlobalAdmin ?? false}
      canUserViewGrid={userProfile?.isGlobalAdmin ?? false}
    />
  );
}
