import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState, useContext, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ImageViewCard from '../sharedComponents/imageViewCard';
import { TabbedEditor } from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import {
  AppType,
  Constants,
  HTTP_METHODS,
  UIContext,
  VclApiGetType,
  VclApiProps,
  formatImageFilename,
  getHostDependentImageUrl,
  useLocalStorage,
  useVclApi,
} from 'vcl-common';
import { validateMetadata, validateRequired } from '../utils/validators';
import { organizationAvailablePermissions } from '../utils/permissions';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import { format, parseISO } from 'date-fns';
import LookupLink from '../sharedComponents/lookupLink';
import StatusChip from '../sharedComponents/statusChip';
import { webcastfilters } from './webcasts';
import ProgressSpinner from '../sharedComponents/progressSpinner';
import { loginRequest } from '../utils/msalConfig';
import { AxiosResponse } from 'axios';

const initMetadata = (current?: any) =>
  initEntityMetadata(
    [
      new EntityProp('id', 0),
      new EntityProp('title', '', [validateRequired]),
      new EntityProp('webHostNames', '', [validateRequired]),
      new EntityProp('adminWebHostNames', '', [validateRequired]),
      new EntityProp('customHeroUrl', null),
      new EntityProp('customThumbnailUrl', null),
      new EntityProp('defaultHeroUrl', null),
      new EntityProp('defaultThumbnailUrl', null),
      new EntityProp('permissions', []),
      new EntityProp(
        'availablePermissionLevels',
        organizationAvailablePermissions,
      ),
      new EntityProp('landingPageImageUrl', null),
      new EntityProp('landingPageTitle', ''),
    ],
    current,
  );

export default function Organization() {
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [canEdit, setCanEdit] = useState(false);

  const uiContext: any = useContext(UIContext);
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [currentIds, setCurrentIds] = useState<number[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [showAddWebcastDialog, setShowAddWebcastDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { getGlobalSettings } = useLocalStorage();
  const globalSettings = getGlobalSettings();

  const apiGetFeaturedWebcastsProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.organizationFeatureWebcastIds,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.All,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
      params: { organizationId: globalSettings?.organization?.id },
    };
  }, [globalSettings?.organization?.id]);
  const { callApi: getFeaturedWebcastIds } = useVclApi<number>(
    apiGetFeaturedWebcastsProps,
  );

  const apiUpdateFeaturedWebcastsProps = useMemo<VclApiProps>(() => {
    const formData = new FormData();
    formData.append('webcastIds', JSON.stringify(selectedIds));

    return {
      apiUrl:
        Constants.routes.api.organizationFeatureWebcasts +
        `/${globalSettings?.organization?.id}`,
      method: HTTP_METHODS.POST,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
      formData: formData,
    };
  }, [globalSettings?.organization?.id, selectedIds]);
  const { callApi: updateFeaturedWebcasts } = useVclApi<number>(
    apiUpdateFeaturedWebcastsProps,
  );

  const apiDeleteFeaturedWebcastsProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl:
        Constants.routes.api.organizationFeatureWebcasts +
        `/${globalSettings?.organization?.id}`,
      method: HTTP_METHODS.DELETE,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, [globalSettings?.organization?.id]);
  const { callApi: deleteFeaturedWebcasts } = useVclApi<number>(
    apiDeleteFeaturedWebcastsProps,
  );

  const { getAuthResult } = useLocalStorage();
  const userProfile = getAuthResult(AppType.AdminWeb)?.userProfile;

  const fetchFeatureWebcastIds = useCallback(
    async (showDialog: boolean) => {
      const response = (await getFeaturedWebcastIds()) as AxiosResponse;
      const ids = response.data as number[];
      setCurrentIds(ids);
      setShowAddWebcastDialog(showDialog);
      setIsLoading(false);
    },
    [getFeaturedWebcastIds],
  );

  const linkWebcastsToOrganization = useCallback(async () => {
    if (selectedIds.length > 0) {
      await updateFeaturedWebcasts();
      setIsLoading(true);
      await fetchFeatureWebcastIds(false);
    } else {
      setShowAddWebcastDialog(false);
    }
  }, [fetchFeatureWebcastIds, selectedIds.length, updateFeaturedWebcasts]);

  const unlinkWebcastsFromOrganization = useCallback(
    async (webcastId: any) => {
      const params = { webcastId: webcastId };
      await deleteFeaturedWebcasts(undefined, undefined, params, undefined);
      setIsLoading(true);
      await fetchFeatureWebcastIds(false);
    },
    [deleteFeaturedWebcasts, fetchFeatureWebcastIds],
  );

  const getEntityTitle = (params: { value: { title: any } | null }) => {
    if (params && params.value) return params.value.title ?? '';
    else return '-1';
  };

  const getIds = (ids: any) => {
    setSelectedIds(ids);
  };

  const columns: any = [
    {
      field: 'thumbnailUrl',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params: { value: string }) => {
        return <Avatar src={getHostDependentImageUrl(params.value)} alt="" />;
      },
    },
    { field: 'title', headerName: 'Title', width: 200, sortable: false },
    {
      field: 'startTime',
      headerName: 'Start Date',
      width: 180,
      type: 'dateTime',
      valueFormatter: (params: { value: string | null }) => {
        return params && params.value
          ? format(parseISO(params.value + 'Z'), 'P p', {
              locale: uiContext.currentLocale,
            })
          : null;
      },
      hide: isXSmallScreen,
      sortable: false,
    },
    {
      field: 'endTime',
      headerName: 'End Date',
      width: 180,
      type: 'dateTime',
      valueFormatter: (params: { value: string | null }) => {
        return params && params.value
          ? format(parseISO(params.value + 'Z'), 'P p', {
              locale: uiContext.currentLocale,
            })
          : null;
      },
      hide: isSmallScreen,
      sortable: false,
    },
    {
      field: 'viewingStateString',
      headerName: 'State',
      width: 150,
      renderCell: (params: { value: string }) => {
        return <StatusChip statusText={params.value} />;
      },
      hide: isSmallScreen,
      sortable: false,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 150,
      valueFormatter: (params: { title: any | null }) => {
        return params && params.title ? params.title : '';
      },
      renderCell: (params: { value: { id: any; title: any } | null }) => {
        if (params && params.value) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.locations} / ${params.value.id}`}
              displayText={params.value.title ?? ''}
            />
          );
        } else return null;
      },
      disableClickEventBubbling: true,
      valueParser: (value: any) => value,
      sortComparator: (v1: any, v2: any, cellParams1: any, cellParams2: any) =>
        getEntityTitle(cellParams1).localeCompare(getEntityTitle(cellParams2)),
      hide: isXSmallScreen,
      sortable: false,
    },
    {
      field: 'channel',
      headerName: 'Channel',
      width: 150,
      valueFormatter: (params: { title: any | null }) => {
        return params && params.title ? params.title : '';
      },
      renderCell: (params: { value: { id: any; title: any } | null }) => {
        if (params && params.value) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.channels} / ${params.value.id}`}
              displayText={params.value.title ?? ''}
            />
          );
        } else return null;
      },
      disableClickEventBubbling: true,
      valueParser: (value: any) => value,
      sortComparator: (v1: any, v2: any, cellParams1: any, cellParams2: any) =>
        getEntityTitle(cellParams1).localeCompare(getEntityTitle(cellParams2)),
      hide: isXSmallScreen,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: { row: { id: any } }) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              unlinkWebcastsFromOrganization(params.row.id);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} key="panel_0" index={0} mode="tabs">
          <TextField
            fullWidth
            required
            id="title"
            name="title"
            label="Title"
            value={metadata.title.value}
            error={!metadata.title.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <TextField
            fullWidth
            required
            id="webHostNames"
            name="webHostNames"
            label="Web Host Names"
            value={metadata.webHostNames.value}
            error={!metadata.webHostNames.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit || !userProfile?.isGlobalAdmin}
          />
          <TextField
            fullWidth
            required
            id="adminWebHostNames"
            name="adminWebHostNames"
            label="Admin Web Host names"
            value={metadata.adminWebHostNames.value}
            error={!metadata.adminWebHostNames.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit || !userProfile?.isGlobalAdmin}
          />
          <Grid container spacing={2} alignItems="top">
            <Grid item>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'org-hero',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.customHeroUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'customHeroUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Hero image"
                defaultImageUrl={getHostDependentImageUrl(
                  metadata.defaultHeroUrl.value,
                )}
                descriptiveText={`This image is used as hero image(top image) for the organization.${
                  !metadata.defaultHeroUrl.value &&
                  'Upload a new picture instead of using the default image placeholder.'
                } `}
                disabled={!canEdit}
              />
            </Grid>
            <Grid item>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'org-thumb',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.customThumbnailUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'customThumbnailUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Thumbnail image"
                defaultImageUrl={getHostDependentImageUrl(
                  metadata.defaultThumbnailUrl.value,
                )}
                descriptiveText={`This image is used as thumbnail for organization.${
                  !metadata.defaultThumbnailUrl.value &&
                  'Upload a new picture instead of using the default image placeholder.'
                }`}
                disabled={!canEdit}
              />
            </Grid>
          </Grid>
        </TabPanel>
      ),
    },
    {
      label: 'Landing Page',
      components: (
        <TabPanel value={activeTab} key="panel_1" index={1} mode="tabs">
          <TextField
            fullWidth
            id="landingPageTitle"
            name="landingPageTitle"
            label="Landing page title"
            value={metadata.landingPageTitle.value}
            error={!metadata.landingPageTitle.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <Grid container spacing={2} alignItems="top">
            <Grid item>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'org-land',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.landingPageImageUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'landingPageImageUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Landing page hero image"
                defaultImageUrl={getHostDependentImageUrl(
                  metadata.defaultHeroUrl.value,
                )}
                descriptiveText={
                  'This image is used as landing page image for the organization'
                }
                disabled={!canEdit}
              />
            </Grid>
          </Grid>
        </TabPanel>
      ),
    },
    {
      label: 'Featured Webcasts',
      components: (
        <TabPanel value={activeTab} index={2} key={'panel_2'} mode={'tabs'}>
          <VCLDataGrid
            title=""
            disableRowClick={true}
            apiUrl={Constants.routes.api.organizationFeatureWebcasts}
            getPersonalizedData={false}
            gridColumns={columns}
            canUserAdd={userProfile?.isGlobalAdmin ?? false}
            canUserViewGrid={userProfile?.isOrgAdminOfAny ?? false}
            onAddClick={async () => {
              await fetchFeatureWebcastIds(true);
            }}
            className={'orgDataGridContainer'}
          />
        </TabPanel>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <TabbedEditor
            entityName="Organization"
            apiUrl={Constants.routes.api.organizations}
            entityRoute={Constants.routes.adminCenter.organizations}
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            metadata={metadata}
            setMetadata={setMetadata}
            canEdit={canEdit}
            canDelete={userProfile?.isGlobalAdmin}
            canEditPermissions={canEdit}
            setCanEdit={setCanEdit}
            usePermissions={true}
            initMetadataFn={initMetadata}
            entityId={itemId}
          />

          <Dialog
            fullWidth
            maxWidth="md"
            open={showAddWebcastDialog}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            onClose={() => setShowAddWebcastDialog(false)}
          >
            <DialogTitle id="scroll-dialog-title">Add Webcasts</DialogTitle>
            <DialogContent dividers={true} className="dialogContentHeight">
              <VCLDataGrid
                title=""
                entityRoute={Constants.routes.adminCenter.webcasts}
                apiUrl={Constants.routes.api.webcasts}
                getPersonalizedData={true}
                filters={webcastfilters}
                gridColumns={[...columns.slice(0, columns.length - 1)]}
                canUserAdd={false}
                defaultFilterName="current"
                viewRoute={Constants.routes.adminCenter.webcastSummary}
                showSelectOption={true}
                initialSelectedIds={currentIds}
                canUserViewGrid={userProfile?.isWebcastAdminOfAny ?? false}
                getSelectedIds={getIds}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={linkWebcastsToOrganization}>Add</Button>
              <Button onClick={() => setShowAddWebcastDialog(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
