import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import LazyAutocomplete from '../sharedComponents/lazyAutocomplete';
import { TabbedEditor } from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import { Constants } from 'vcl-common';
import { validateMetadata, validateRequired } from '../utils/validators';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';

const initMetadata = (current?: any) =>
  initEntityMetadata(
    [
      new EntityProp('id', 0),
      new EntityProp('title', '', [validateRequired]),
      new EntityProp('locality', '', [validateRequired]),
      new EntityProp('region', null),
      new EntityProp('regionId', null, [validateRequired]),
    ],
    current,
  );

export default function Location() {
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [canEdit, setCanEdit] = useState(false);

  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} key="panel_0" index={0} mode="tabs">
          <TextField
            fullWidth
            required
            id="title"
            name="title"
            label="Title"
            value={metadata.title.value}
            error={!metadata.title.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <TextField
            fullWidth
            multiline
            id="locality"
            name="locality"
            label="Location"
            value={metadata.locality.value}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
            helperText='This can be the city, state or another locality. For example "Torslanda, Gothenburg"'
            required
            error={!metadata.locality.isValid}
          />
          <LazyAutocomplete
            initialValue={metadata.region?.value}
            label="Region"
            error={metadata.regionId.isValid === false}
            onNavigate={(item: { id: any }) =>
              window.open(`${Constants.routes.adminCenter.regions}/${item.id}`)
            }
            disabled={itemId !== 'new'}
            apiUrl={Constants.routes.api.regions}
            metadata={metadata}
            setMetadata={setMetadata}
            idColumnName="regionId"
            valColumnName="region"
            required
          />
        </TabPanel>
      ),
    },
  ];

  return (
    <TabbedEditor<number>
      entityName="Location"
      apiUrl={Constants.routes.api.locations}
      entityRoute={Constants.routes.adminCenter.locations}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      setMetadata={setMetadata}
      canEdit={canEdit}
      canDelete={canEdit}
      setCanEdit={setCanEdit}
      usePermissions={false}
      initMetadataFn={initMetadata}
      entityId={itemId}
    />
  );
}
