import VCLDataGrid from '../sharedComponents/vclDataGrid';
import {
  AppType,
  Constants,
  getHostDependentImageUrl,
  useLocalStorage,
} from 'vcl-common';
import { Avatar } from '@mui/material';

export default function Organizations() {
  const { getAuthResult } = useLocalStorage();
  const userProfile = getAuthResult(AppType.AdminWeb)?.userProfile;

  const columns = [
    {
      field: 'thumbnailUrl',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params: { value: string }) => {
        return <Avatar src={getHostDependentImageUrl(params.value)} alt="" />;
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 300,
      editable: false,
      sortable: false,
    },
  ];

  return (
    <VCLDataGrid
      title="Organizations"
      apiUrl={Constants.routes.api.organizations}
      entityRoute={Constants.routes.adminCenter.organizations}
      getPersonalizedData={true}
      gridColumns={columns}
      canUserAdd={userProfile?.isGlobalAdmin ?? false}
      canUserViewGrid={userProfile?.isOrgAdminOfAny ?? false}
    />
  );
}
