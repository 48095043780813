import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { useCallback, useMemo, useState } from 'react';
import {
  Constants,
  imageQueries,
  VclApiProps,
  HTTP_METHODS,
  useVclApi,
} from 'vcl-common';
import ImageViewDialog from './imageViewDialog';
import { PageState } from '../utils/saveProcess';
import MessageBar from './messageBar';
import { AxiosResponse } from 'axios';
import { loginRequest } from '../utils/msalConfig';

const useStyles = makeStyles()({
  root: {
    maxWidth: 306,
  },
  media: {
    height: 172,
  },
});

export default function ImageViewCard(props: any) {
  const {
    newImageFileName,
    customImageUrl,
    defaultImageUrl,
    fnUpdateImage,
    parentType,
    parentName,
  } = props;

  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [saveProgress, setSaveProgress] = useState(PageState.UNTOUCHED);
  const [saveError, setSaveError] = useState('');

  const apiProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.image,
      method: HTTP_METHODS.POST,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, []);
  const { callApi, apiError } = useVclApi<any>(apiProps);

  const uploadFile = useCallback(
    async (event: any) => {
      setSaveProgress(PageState.SAVING);
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', newImageFileName);

      const response = (await callApi(
        undefined,
        undefined,
        undefined,
        formData,
      )) as AxiosResponse;
      fnUpdateImage(response.headers.location);
    },
    [callApi, fnUpdateImage, newImageFileName],
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (apiError?.isError && saveProgress !== PageState.SAVE_ERROR) {
    setSaveError(apiError.errorMessage);
    setSaveProgress(PageState.SAVE_ERROR);
  }

  const currentImageUrl = customImageUrl || defaultImageUrl;
  const imageWOQuery =
    currentImageUrl && currentImageUrl.indexOf('?') > -1
      ? currentImageUrl.substring(0, currentImageUrl.indexOf('?'))
      : currentImageUrl;
  const thumbnailUrl =
    imageWOQuery && imageWOQuery.length > 0
      ? `${imageWOQuery}?${imageQueries.thumbnail}`
      : '';

  return (
    <>
      <MessageBar
        open={saveProgress === PageState.SAVE_ERROR}
        message={'Error' + (saveError ? `: ${saveError}` : '')}
        key="snack-error"
        confirmationType="Error"
        buttonText="OK"
        onButtonClick={() => setSaveProgress(PageState.UNTOUCHED)}
      ></MessageBar>
      <Card className={classes.root}>
        <CardActionArea>
          {thumbnailUrl && (
            <CardMedia
              className={classes.media}
              image={thumbnailUrl}
              title={props.imageTitle}
            />
          )}
          <CardContent>
            <Typography gutterBottom variant="h6">
              {props.imageTitle}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              sx={defaultImageUrl && { mb: 2 }}
            >
              {props.descriptiveText}
            </Typography>
            {defaultImageUrl && (
              <Typography variant="body2" color="textSecondary" component="p">
                {customImageUrl && (
                  <>
                    Click on <strong>Revert</strong> to remove the uploaded
                    image, and inherit the image from the {parentType}{' '}
                    <strong>{parentName}</strong> instead.
                  </>
                )}
                {!customImageUrl && (
                  <>
                    The image is currently inherited from the {parentType}{' '}
                    <strong>{parentName}</strong>.
                  </>
                )}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            color="secondary"
            component="label"
            disabled={props.disabled}
          >
            Upload New
            <input type="file" hidden onChange={(event) => uploadFile(event)} />
          </Button>
          <Button
            disabled={props.disabled || currentImageUrl === defaultImageUrl}
            size="small"
            color="secondary"
            onClick={() => fnUpdateImage(null)}
          >
            Revert
          </Button>
          {thumbnailUrl && (
            <Button
              size="small"
              color="primary"
              onClick={handleClickOpen}
              endIcon={<OpenInNewIcon />}
            >
              View
            </Button>
          )}
        </CardActions>
      </Card>
      <ImageViewDialog
        id="image-view-dialog"
        imageCaption={props.imageTitle}
        image={imageWOQuery}
        open={open}
        onClose={handleClose}
        format={props.format}
      />
    </>
  );
}
