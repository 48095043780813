import LookupLink from '../sharedComponents/lookupLink';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import { AppType, Constants, useLocalStorage } from 'vcl-common';

export default function GlobalAdmins() {
  const { getAuthResult } = useLocalStorage();
  const userProfile = getAuthResult(AppType.AdminWeb)?.userProfile;

  const columns = [
    {
      field: 'name',
      headerName: 'Full Name',
      width: 300,
      sortable: false,
      editable: false,
      renderCell: (params: {
        value: null;
        row: { user: { fullName: any } };
      }) => {
        if (params && params.row && params.row.user) {
          return params.row.user.fullName ?? '';
        } else return null;
      },
    },
    {
      field: 'username',
      headerName: 'Username',
      width: 300,
      sortable: false,
      editable: false,
      renderCell: (params: { row: { user: { userName: any } } }) => {
        if (params && params.row && params.row.user) {
          return params.row.user.userName ?? '';
        } else return null;
      },
    },
    {
      field: 'userId',
      headerName: 'User',
      width: 300,
      sortable: false,
      editable: false,
      renderCell: (params: { row: { userId: any } }) => {
        if (params && params.row && params.row.userId) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.users}/${params.row.userId}`}
              displayText="User"
            />
          );
        } else return null;
      },
    },
  ];

  return (
    <VCLDataGrid
      title="Global Admins"
      apiUrl={Constants.routes.api.globalAdmins}
      entityRoute={Constants.routes.adminCenter.globalAdmins}
      getPersonalizedData={false}
      gridColumns={columns}
      canUserAdd={userProfile?.isGlobalAdmin ?? false}
      canUserViewGrid={userProfile?.isGlobalAdmin ?? false}
    />
  );
}
