import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  VclApiProps,
  HTTP_METHODS,
  VclApiGetType,
  useVclApi,
  IBaseEntity,
  IBaseSecurableEntity,
  IBasePermission,
} from 'vcl-common';
import ConfirmDeleteDialog from './confirmDeleteDialog';
import PermissionList from './permissionList';
import SaveStateMessages from './saveStateMessages';
import TabbedContent from './tabbedContent';
import TabPanel from './tabPanel';
import { isCurrentUserEditorOfThis } from '../utils/permissions';
import { createEntityFromPageMetadata, PageState } from '../utils/saveProcess';
import { loginRequest } from '../utils/msalConfig';
import { AxiosResponse } from 'axios';

interface TabbedEditorProps {
  activeTab?: number;
  setActiveTab?: any;
  metadata?: any;
  setMetadata?: any;
  canEdit?: boolean;
  canDelete?: boolean;
  canEditPermissions?: boolean;
  setCanEdit?: any;
  canSave?: boolean;
  usePermissions?: any;
  apiUrl?: any;
  initMetadataFn?: any;
  tabs?: any;
  entityRoute?: any;
  entityName?: any;
  entityId?: number | string;
  setMyRoles?: any;
  isDraft?: boolean;
  saveCb?: any;
  loadMetadataCb?: (metadata: any, setPermissions: any) => Promise<void>;
  myRoles?: any;
  customDeleteMsg?: string;
}

export function TabbedEditor<TId>(initialValues: TabbedEditorProps) {
  const {
    activeTab,
    setActiveTab,
    metadata,
    setMetadata,
    canEdit,
    canDelete,
    canEditPermissions,
    setCanEdit,
    canSave,
    usePermissions,
    apiUrl,
    initMetadataFn,
    tabs,
    entityRoute,
    entityName,
    entityId,
    setMyRoles,
    saveCb,
    loadMetadataCb,
    customDeleteMsg,
  } = initialValues;

  const navigate = useNavigate();
  const [saveProgress, setSaveProgress] = useState(PageState.UNTOUCHED);
  const [permissions, setPermissions] = useState<any>([]);
  const permissionsToUpdate = useRef<any[]>([]);
  const permissionsToDelete = useRef<any[]>([]);
  const permissionsToAdd = useRef<any[]>([]);
  const [deleteDialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const enableValidation = useRef(false);
  const [saveError, setSaveError] = useState('');
  const savedMetadata = useRef<any>(metadata);

  const isNewRequest = useMemo(() => entityId === 'new', [entityId]);

  const apiGetProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: `${apiUrl}/${entityId}`,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
      fetchImmediately: !isNewRequest,
    };
  }, [apiUrl, entityId, isNewRequest]);
  const {
    apiResponse: entityResponse,
    apiRequestInProgress: getEntityInProgress,
  } = useVclApi<IBaseEntity<TId>>(apiGetProps);

  const metadataEntity = useMemo(
    () => createEntityFromPageMetadata(metadata),
    [metadata],
  );
  const apiSaveProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: apiUrl,
      method: isNewRequest ? HTTP_METHODS.POST : HTTP_METHODS.PUT,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
      formData: metadataEntity,
    };
  }, [apiUrl, isNewRequest, metadataEntity]);
  const {
    callApi: apiSaveEntity,
    apiRequestInProgress: apiSaveInProgress,
    apiError: saveEntityError,
  } = useVclApi<IBaseEntity<TId>>(apiSaveProps);

  const apiDeleteProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: `${apiUrl}/${entityId}`,
      method: HTTP_METHODS.DELETE,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, [apiUrl, entityId]);
  const {
    callApi: apiDeleteEntity,
    apiRequestInProgress: apiDeleteInProgress,
    apiError: deleteEntityError,
  } = useVclApi<IBaseEntity<TId>>(apiDeleteProps);

  const apiSavePermsProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: apiUrl,
      method: HTTP_METHODS.POST,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, [apiUrl]);
  const {
    savePermissions: apiSavePermissions,
    apiRequestInProgress: apiSavePermsInProgress,
    apiError: savePermsError,
  } = useVclApi<IBasePermission>(apiSavePermsProps);

  const isPageValid = useMemo(() => {
    if (!metadata || !enableValidation.current) return true;

    for (let i = 0; i < Object.keys(metadata).length; i++) {
      const key = Object.keys(metadata)[i];
      if (!metadata[key].isValid) {
        return false;
      }
    }
    return true;
  }, [metadata]);

  if (isNewRequest && !canEdit && loading) {
    setCanEdit(true);
    enableValidation.current = true;
    setLoading(false);
  } else if (!getEntityInProgress && !!entityResponse?.data && loading) {
    const entity = entityResponse?.data;
    setMetadata(initMetadataFn(entity));
    savedMetadata.current = initMetadataFn(entity);
    setCanEdit(isCurrentUserEditorOfThis(entity.currentUserRoles));

    // The myRoles state is not used by all parent components, so check first if it exists
    if (setMyRoles) {
      setMyRoles(entity.currentUserRoles);
    }
    if (usePermissions) {
      const securableEntity = entity as IBaseSecurableEntity<
        TId,
        IBasePermission
      >;
      setPermissions(securableEntity.permissions);
    }
    if (loadMetadataCb) {
      loadMetadataCb(entityResponse, setPermissions);
    }

    setLoading(false);
    enableValidation.current = true;
  }

  // Set the page state based upon metadata changes
  useEffect(() => {
    let state = PageState.UNTOUCHED;

    if (enableValidation.current) {
      if (!isPageValid) {
        state = PageState.VALIDATION_ERROR;
      } else {
        // Compare the updated metadata with the saved one to figure out if changes have been made
        for (let i = 0; i < Object.keys(metadata).length; i++) {
          const key = Object.keys(metadata)[i];
          if (
            !!metadata[key].value &&
            metadata[key].value !== savedMetadata.current[key].value
          ) {
            state = PageState.DIRTY;
            break;
          }
        }
      }
    }

    setSaveProgress(state);
  }, [isPageValid, metadata]);

  const saveEntity = useCallback(async () => {
    const response = (await apiSaveEntity()) as AxiosResponse;
    savedMetadata.current = metadata;
    const savedEntity = response.data as IBaseEntity<TId>;
    const savedItemId = metadata?.id <= 0 ? savedEntity.id : metadata.id;

    if (usePermissions && !!metadata?.availablePermissionLevels?.value) {
      await apiSavePermissions(
        savedItemId,
        permissionsToAdd.current,
        permissionsToUpdate.current,
        permissionsToDelete.current,
        metadata.availablePermissionLevels.value,
      );
    }

    if (saveEntityError.isError) {
      setSaveError(saveEntityError.errorMessage);
    } else if (savePermsError.isError) {
      setSaveError(savePermsError.errorMessage);
    } else if (saveCb) {
      await saveCb(savedItemId);
    }

    setSaveProgress(PageState.SAVE_SUCCESS);
    navigate(entityRoute);
  }, [
    apiSaveEntity,
    apiSavePermissions,
    entityRoute,
    metadata,
    navigate,
    saveCb,
    saveEntityError.errorMessage,
    saveEntityError.isError,
    savePermsError.errorMessage,
    savePermsError.isError,
    usePermissions,
  ]);

  const handleTabChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const handleSaveClick = (event: any) => {
    setSaveProgress(PageState.SAVING);
  };

  const handleCancelClick = (event: any) => {
    navigate(entityRoute);
  };

  const handleDeleteClick = useCallback(
    async (event: any) => {
      await apiDeleteEntity();

      if (deleteEntityError.isError) {
        setSaveError(deleteEntityError.errorMessage);
        setSaveProgress(PageState.SAVE_ERROR);
        setDialogOpen(false);
      } else if (!apiDeleteInProgress) {
        navigate(entityRoute);
      }
    },
    [
      apiDeleteEntity,
      apiDeleteInProgress,
      deleteEntityError.errorMessage,
      deleteEntityError.isError,
      entityRoute,
      navigate,
    ],
  );

  let tabSections = tabs;
  if (usePermissions) {
    tabSections = [
      ...tabs,
      {
        label: 'Permissions',
        components: (
          <TabPanel
            value={activeTab}
            index={tabs.length}
            key={`panel_${tabs.length}`}
            mode={'tabs'}
          >
            <PermissionList
              permissionsToUpdate={permissionsToUpdate}
              permissionsToDelete={permissionsToDelete}
              permissionsToAdd={permissionsToAdd}
              permissions={permissions}
              setPermissions={setPermissions}
              availablePermLevels={metadata.availablePermissionLevels.value}
              canEdit={canEditPermissions}
              disabled={
                !canEdit || (canSave !== undefined && canSave === false)
              }
              entity={entityName}
              metadata={metadata}
            />
          </TabPanel>
        ),
      },
    ];
  }

  return (
    <>
      <SaveStateMessages
        updateProgess={(newState: any) => setSaveProgress(newState)}
        saveState={saveProgress}
        saveAction={() => saveEntity()}
        entityTypeName={entityName}
        errorMessage={saveError}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <h2>{entityName}</h2>
        </Grid>
      </Grid>
      <TabbedContent
        sections={tabSections}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        onFinish={handleSaveClick}
        mode={'tabs'}
        loading={loading}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          id="buttonsGrid"
        >
          <Button
            name="saveButton"
            id="saveButton"
            variant="contained"
            disabled={
              saveProgress !== PageState.DIRTY ||
              !canEdit ||
              (canSave !== undefined && canSave === false)
            }
            onClick={handleSaveClick}
            color="secondary"
          >
            Save
          </Button>
          <Button
            name="cancelButton"
            id="cancelButton"
            variant="outlined"
            disabled={saveProgress > PageState.DIRTY}
            onClick={handleCancelClick}
            color="secondary"
          >
            Cancel
          </Button>
          {!isNewRequest && (
            <Button
              name="deleteButton"
              id="deleteButton"
              variant="contained"
              disabled={
                !canEdit ||
                !canDelete ||
                (canSave !== undefined && canSave === false)
              }
              onClick={() => setDialogOpen(true)}
              color="error"
            >
              Delete
            </Button>
          )}
        </Grid>
      </TabbedContent>
      <ConfirmDeleteDialog
        show={deleteDialogOpen}
        title={metadata?.title?.value}
        onConfirm={handleDeleteClick}
        onClose={() => setDialogOpen(false)}
        customDeleteMsg={customDeleteMsg}
      />
    </>
  );
}
