import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageViewCard from '../sharedComponents/imageViewCard';
import LazyAutocomplete from '../sharedComponents/lazyAutocomplete';
import { TabbedEditor } from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import {
  AppType,
  Constants,
  formatImageFilename,
  getDefaultHeroFromParent,
  getDefaultThumbnailFromParent,
  getHostDependentImageUrl,
  useLocalStorage,
} from 'vcl-common';
import { validateMetadata, validateRequired } from '../utils/validators';
import { regionAvailablePermissions } from '../utils/permissions';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';

const initMetadata = (current?: any) =>
  initEntityMetadata(
    [
      new EntityProp('id', 0),
      new EntityProp('title', '', [validateRequired]),
      new EntityProp('customHeroUrl', null),
      new EntityProp('customThumbnailUrl', null),
      new EntityProp('defaultHeroUrl', null),
      new EntityProp('defaultThumbnailUrl', null),
      new EntityProp('organization', null),
      new EntityProp('organizationId', null, [validateRequired]),
      new EntityProp('permissions', []),
      new EntityProp('availablePermissionLevels', regionAvailablePermissions),
    ],
    current,
  );

export default function Region() {
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [canEdit, setCanEdit] = useState(false);

  const { getAuthResult } = useLocalStorage();
  const userProfile = getAuthResult(AppType.AdminWeb)?.userProfile;

  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} key="panel_0" index={0} mode="tabs">
          <TextField
            fullWidth
            required
            id="title"
            name="title"
            label="Title"
            value={metadata.title.value}
            error={!metadata.title.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit || !userProfile?.isOrgAdminOfAny}
          />
          <LazyAutocomplete
            initialValue={metadata.organization?.value}
            label="Organization"
            error={metadata.organizationId.isValid === false}
            onNavigate={(item: { id: any }) =>
              window.open(
                `${Constants.routes.adminCenter.organizations}/${item.id}`,
              )
            }
            disabled={itemId !== 'new'}
            apiUrl={Constants.routes.api.organizations}
            metadata={metadata}
            setMetadata={setMetadata}
            idColumnName="organizationId"
            valColumnName="organization"
            required
          />
          <Grid container spacing={2} alignItems="top">
            <Grid item>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'region-hero',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.customHeroUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'customHeroUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Hero image"
                defaultImageUrl={getHostDependentImageUrl(
                  getDefaultHeroFromParent(metadata.organization?.value),
                )}
                descriptiveText="This image is used as hero image (top image) for channels and webcasts in this region."
                disabled={!canEdit}
                parentType="organization"
                parentName={metadata.organization?.value?.title}
              />
            </Grid>
            <Grid item>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'region-thumb',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.customThumbnailUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'customThumbnailUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Thumbnail image"
                defaultImageUrl={getHostDependentImageUrl(
                  getDefaultThumbnailFromParent(metadata.organization?.value),
                )}
                descriptiveText="This image is used as thumbnail for webcasts in this region."
                disabled={!canEdit}
                parentType="organization"
                parentName={metadata.organization?.value?.title}
              />
            </Grid>
          </Grid>
        </TabPanel>
      ),
    },
  ];

  return (
    <TabbedEditor
      entityName="Region"
      apiUrl={Constants.routes.api.regions}
      entityRoute={Constants.routes.adminCenter.regions}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      setMetadata={setMetadata}
      canEdit={canEdit}
      canDelete={userProfile?.isOrgAdminOfAny}
      canEditPermissions={userProfile?.isOrgAdminOfAny}
      setCanEdit={setCanEdit}
      usePermissions
      initMetadataFn={initMetadata}
      entityId={itemId}
    />
  );
}
