import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { useLocalStorage, AppType } from 'vcl-common';

function stringToColor(string: any) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, photo: string, width: any, height: any) {
  const sx: any = {
    bgcolor: stringToColor(name ? name : '  '),
  };
  if (width) sx.width = width;
  if (height) sx.height = height;
  return {
    sx: sx,
    src: photo,
    children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : '',
  };
}

export default function UserAvatar(props: any) {
  const { getOrgUserProfile, getUserProfilePhotoUrl } = useLocalStorage();
  const userProfile = getOrgUserProfile();
  const photoUrl = getUserProfilePhotoUrl(AppType.AdminWeb);

  const width = props.width;
  const height = props.height;

  return (
    <Tooltip title={userProfile?.displayName}>
      <Avatar
        {...stringAvatar(
          userProfile?.displayName ?? '',
          photoUrl,
          width,
          height,
        )}
      />
    </Tooltip>
  );
}
